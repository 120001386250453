import createCache from '@emotion/cache';

export { default as createEmotionCache } from '@emotion/cache';

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends BytearkTheme {}
}

export const emotionCache = createCache({
  key: 'byteark',
});

export const bytearkTheme = {
  colors: {
    byteark: {
      primary: '#106FF5',
      white: '#FFFFFF',
      gray: '#FFFFFF80',
      background: '#151515',
    },
  },
};

type BytearkTheme = typeof bytearkTheme;
